<template>
    <div 
        class="-mt-2 flex justify-between flex-wrap items-center">
        <div class="mt-2 mr-4 flex items-center">
            <span class="mr-2 font-semibold">Руководитель:</span>
            <Profiler
                hideSupportTag
                :user="organization.director" />
        </div>
        <div 
            class="mt-2 flex items-center ml-auto">
            <template v-if="canManage && !isDepartment">
                <a-button
                    v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                    content="Копировать идентификатор"
                    class="mr-2"
                    type="ui"
                    shape="circle"
                    flaticon
                    icon="fi-rr-copy-alt"
                    @click="orgCopyId()">
                </a-button>
                <a-button
                    v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                    content="Пригласить структурное подразделение"
                    type="primary"
                    shape="circle"
                    @click="openOrganizationInvite"
                    ghost
                    class="flex items-center justify-center">
                    <i class="fi fi-rr-plus"></i>
                </a-button>
            </template>
            <template v-if="canDelete">
                <a-button
                    v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }"
                    :content="labelDelete"
                    type="danger"
                    shape="circle"
                    ghost
                    @click="openDeleteModal"
                    class="ml-2 flex items-center justify-center">
                    <i :class="deleteIcon"></i>
                </a-button>
            </template>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        organization: {
            type: Object,
            required: true,
        },
        relationId: {
            type: String,
            default: null
        },
        parentId: {
            type: String,
            default: null
        },
        isDepartment: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            isMobile: state => state.isMobile,
            actionInfo: state => state.organization.actionInfo
        }),
        permissions() {
            return this.actionInfo?.[this.organization.id]
        },
        parentPermissions() {
            return this.actionInfo?.[this.parentId]
        },
        canManage() {
            return this.permissions?.manage?.availability
        },
        canDelete() {
            if(this.isDepartment)
                return this.parentPermissions?.manage?.availability
            // return this.permissions?.manage?.availability
            return false
        },
        labelDelete() {
            if(this.isDepartment)
                return 'Удалить отдел из структуры'
            if(this.relationId)
                return 'Удалить узел из структуры'
            return 'Удалить организацию'
        },
        deleteIcon() {
            if(this.relationId)
                return 'fi fi-rr-minus'
            return 'fi fi-rr-trash'
        }
    },
    methods: {
        ...mapActions({
            deleteDepartment: 'organization/deleteDepartment',
            deleteOrganization: 'organization/deleteOrganization',
        }),
        orgCopyId() {
            try {
                navigator.clipboard.writeText(this.organization.id)
                this.$message.success('Идентификатор организации скопирован')
            } catch(error) {
                console.error(error)
                this.$message.error('Ошибка')
            }
        },
        openOrganizationInvite() {
            eventBus.$emit('invite_organization', { organization: this.organization, isSubdivision: false })
        },
        openOrgInvite() {
            eventBus.$emit('invite_organization', this.organization)
        },
        untieOrganization() {
            try {
                this.$http.put(`/users/my_organizations/relations/${this.relationId}/update/`, {
                    contractor_parent: null
                })
                this.$store.commit('organization/UNTIE_ORGANIZATION', {
                    parentId: this.parentId,
                    relationId: this.relationId
                })
            } catch(error) {
                console.error(error)
            }
        },
        editOrganization() {
            eventBus.$emit('edit_organization', {
                organization: this.organization,
                organizationParent: this.parentId,
                isDepartment: this.isDepartment
            })
        },
        openDeleteModal() {
            const self = this
            let title = 'Вы уверены, что хотите удалить ' 
            if(this.relationId) {
                title += 'узел из структуры?'
            } else if(this.isDepartment) {
                title += 'отдел?'
            } else {
                title += 'организацию?'
            }
            this.$confirm({
                title: title,
                content: '',
                okText: 'Да',
                cancelText: 'Нет',
                onOk() {
                    if(self.relationId) {
                        self.untieOrganization()
                    } else if(self.isDepartment) {
                        self.deleteDepartment({
                            departmentId: self.organization.id,
                            parentId: self.parentId
                        })
                    } else {
                        self.deleteOrganization({
                            organizationId: self.organization.id,
                            parentId: self.parentId
                        })
                    }
                },
                onCancel() {},
            })
        },
    }
}
</script>